import { Icon, IconProps } from '@chakra-ui/react';

const LogoIcon = (props: IconProps) => (
  <Icon viewBox="20 20 60 60" {...props}>
    <g fill="currentColor" data-fill-palette-color="accent">
      <path
        d="M50 22.988c-14.474 0-26.25 11.775-26.25 26.25 0 7.918 1.919 12.812 6.841 17.425 0.252 0.681-0.182 3.512-0.417 5.046-0.473 3.088-0.585 4.142-0.093 4.802 0.192 0.259 0.47 0.425 0.782 0.468 0.158 0.022 0.322 0.033 0.492 0.033 1.719 0 3.917-1.144 5.857-2.151 1.026-0.534 2.423-1.26 2.917-1.293 3.136 1.274 6.457 1.92 9.871 1.92 14.475 0 26.25-11.775 26.25-26.249C76.25 34.764 64.475 22.988 50 22.988zM50 73.487c-3.178 0-6.268-0.605-9.183-1.8-0.942-0.381-2.235 0.207-4.527 1.398-1.478 0.768-3.28 1.705-4.524 1.892 0.042-0.732 0.234-1.986 0.384-2.966 0.618-4.032 0.792-5.891-0.135-6.755-4.306-4.015-6.098-8.298-6.25-15.056 0-0.018 0.01-0.032 0.01-0.05 0-0.02-0.01-0.037-0.012-0.057-0.005-0.284-0.013-0.564-0.013-0.856 0-13.372 10.878-24.25 24.25-24.25 13.371 0 24.25 10.878 24.25 24.25C74.25 62.609 63.371 73.487 50 73.487z"
        data-fill-palette-color="accent"
      />
      <path
        d="M71.69 49.151h-6.204c-0.097-5.524-0.964-9.877-1.344-11.533 1.106-0.07 2.204-0.145 3.268-0.219 0.551-0.039 0.966-0.518 0.927-1.068s-0.522-0.961-1.067-0.927c-1.163 0.082-2.364 0.162-3.578 0.238l-1.139-4.13c-0.148-0.533-0.703-0.839-1.231-0.694-0.532 0.148-0.843 0.7-0.694 1.231l1.036 3.716c-4.048 0.233-7.625 0.361-10.663 0.382v-8.396c0-0.553-0.448-1-1-1s-1 0.447-1 1v8.39c-3.064-0.025-6.386-0.128-9.753-0.301 0.415-1.778 1.167-4.466 1.176-4.499 0.149-0.532-0.161-1.084-0.693-1.233-0.533-0.151-1.084 0.161-1.233 0.693-0.042 0.148-0.87 3.109-1.276 4.932-1.559-0.086-3.087-0.179-4.542-0.279-0.548-0.037-1.028 0.377-1.067 0.928-0.038 0.552 0.377 1.029 0.928 1.067 1.374 0.095 2.814 0.183 4.28 0.265-0.403 1.671-1.221 5.734-1.309 11.438H28.3c-0.552 0-1 0.448-1 1 0 0.553 0.448 1 1 1h7.211c0.088 5.718 0.905 9.771 1.309 11.438-1.087 0.063-2.165 0.13-3.21 0.204-0.551 0.038-0.966 0.517-0.927 1.067 0.037 0.527 0.476 0.93 0.997 0.93 0.023 0 0.047-0.001 0.071-0.003 1.143-0.08 2.323-0.153 3.518-0.221l0.755 3.633c0.102 0.469 0.516 0.788 0.976 0.788 0.07 0 0.142-0.007 0.213-0.022 0.54-0.117 0.882-0.65 0.765-1.189l-0.72-3.316c3.545-0.184 6.812-0.285 9.743-0.302v7.023c0 0.553 0.448 1 1 1s1-0.447 1-1v-7.023c3.215 0.026 6.817 0.145 10.551 0.346l-0.838 2.26c-0.192 0.518 0.072 1.093 0.59 1.285 0.114 0.043 0.232 0.062 0.348 0.062 0.406 0 0.788-0.249 0.938-0.652l1.051-2.833 2.607 0.163c0.542 0.027 1.025-0.384 1.062-0.935 0.035-0.551-0.384-1.026-0.935-1.062l-2.17-0.136c0.427-1.53 1.209-5.184 1.286-11.506h6.201c0.553 0 1-0.447 1-1C72.69 49.6 72.243 49.151 71.69 49.151zM51 38.147c3.16-0.021 6.89-0.156 11.116-0.404 0.29 1.211 1.264 5.693 1.369 11.408H51V38.147zM38.855 37.824c3.497 0.184 6.958 0.292 10.145 0.317v11.01H37.512C37.61 43.196 38.543 39.05 38.855 37.824zM38.855 62.479c-0.312-1.222-1.246-5.354-1.343-11.327H49v11.007C45.952 62.175 42.55 62.282 38.855 62.479zM62.152 62.535c-3.945-0.221-7.761-0.35-11.152-0.377V51.151h12.488C63.4 58.047 62.442 61.615 62.152 62.535z"
        data-fill-palette-color="accent"
      />
    </g>
  </Icon>
);

export default LogoIcon;
